.bulletActiveClass {
  background-color: #fff;
  width: 31px !important;
  border-radius: 8px !important;
}

.bulletClass {
  transition: all 0.3s linear;
  margin: 0 5px;
  width: 9px;
  height: 9px;
  border-radius: 50%;
  display: inline-block;
  border: thin solid #fff;
  cursor: pointer;
}
